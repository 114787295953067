import styled from "@emotion/styled";

import { Loading } from "scmp-app/components/common/loading";

export const StyledLoading = styled(Loading)`
  margin-block: 40px;
`;

export const TopRibbon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  inline-size: 100%;
  block-size: 92px;

  color: #555555;
  font-size: 20px;
  font-style: italic;

  background: #f0f0f0;
`;
