import type { FunctionComponent } from "react";

import { PageSeo } from "scmp-app/components/meta";
import {
  PostiesDescription as description,
  PostiesAdditionalLinkTags,
  PostiesTitle as title,
} from "scmp-app/components/meta/consts";

import PostiesImage from "./posties-image.png";

export const PostiesSeo: FunctionComponent = () => (
  <PageSeo
    additionalLinkTags={PostiesAdditionalLinkTags}
    additionalMetaTags={undefined}
    description={description}
    openGraph={{
      description,
      images: [{ url: PostiesImage.src }],
      siteName: title,
      title,
    }}
    options={{
      customMerge(key) {
        if (key === "additionalLinkTags") {
          return (_, source: unknown[]) => source;
        }
      },
    }}
    title={title}
    twitter={undefined}
  />
);

PostiesSeo.displayName = "PostiesSeo";
