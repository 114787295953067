import { notEmpty } from "@product/scmp-sdk";
import { useAtomValue } from "jotai";
import type { NextPage, PageProps } from "next";
import { useRouter } from "next/router";
import qs from "qs";
import { useEffect } from "react";

import { PostiesSeo } from "scmp-app/components/posties/posties-seo";
import { rosettaAtom } from "scmp-app/lib/rosetta";

import { page as PostiesKidsPage } from "./kids";
import { page as PostiesParentsPage } from "./parents";
import { StyledLoading } from "./styles";

export type PostiesPageQueryParameters = {
  module: string;
  pgtype: string;
};

const Posties: NextPage<PageProps> = () => {
  const asyncRosettaState = useAtomValue(rosettaAtom);
  const router = useRouter();

  useEffect(() => {
    if (!notEmpty(asyncRosettaState) || asyncRosettaState?.status === "loading") return;

    const { pathname: postiesKidsPagePathname, query: postiesKidsPageQuery } =
      PostiesKidsPage.route({});
    const { pathname: postiesParentsPagePathname, query: postiesParentsPageQuery } =
      PostiesParentsPage.route({});

    const parsedQueryString = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    }) as unknown as PostiesPageQueryParameters;

    const redirectedURL = asyncRosettaState?.result?.hasPostiesAccessRight
      ? {
          pathname: postiesKidsPagePathname,
          query: {
            ...postiesKidsPageQuery,
            ...parsedQueryString,
          },
        }
      : {
          pathname: postiesParentsPagePathname,
          query: {
            ...postiesParentsPageQuery,
            ...parsedQueryString,
          },
        };

    void router.replace(redirectedURL);
  }, [
    asyncRosettaState,
    asyncRosettaState?.result?.hasPostiesAccessRight,
    asyncRosettaState?.status,
    router,
  ]);

  return (
    <>
      <PostiesSeo />
      <StyledLoading />
    </>
  );
};

Posties.displayName = "Posties";

const pageProps: PageProps = {
  appBarConfiguration: {
    variant: "posties/generic",
  },
  appInitializerConfiguration: {
    application: "posties",
  },
};

Posties.getInitialProps = () => ({
  ...pageProps,
});

export default Posties;
